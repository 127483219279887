import { Component, OnInit, OnDestroy, ViewChild, HostListener, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom, Observable, Subscription } from "rxjs";
import { map, take } from "rxjs/operators";
import { NgbNav } from "@ng-bootstrap/ng-bootstrap";

import { TargetsService } from "../targets.service";
import { AnyTarget, TargetApiType, TargetPidMappingTypes } from "../../channels/channel";
import { Constants } from "../../../constants/constants";
import { Source, Tag, UserPermissions } from "../../../models/shared";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";

// Components
import { ZxEventsComponent } from "../../../components/shared/zx-events/zx-events.component";
import { TargetTracerouteComponent } from "./target-traceroute/target-traceroute.component";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { GraphsService } from "src/app/services/graphs.service";
import { urlBuilder } from "@zixi/shared-utils";
import { OBJECT_TYPE } from "../../networks/network-objects-state/network-objects-state.service";
import { SourcesService } from "../../sources/sources.service";
import { FormControl } from "@angular/forms";
import { ResizeService } from "src/app/services/resize.service";
import { ObjectsActiveBroadcaster } from "@zixi/models";
import { RecoveryState } from "src/app/components/shared/new-details-page/details-page-header/details-page-header-drop-down/details-page-header-drop-down.component";
import { ChannelsService } from "../../channels/channels.service";

@Component({
    selector: "app-target",
    templateUrl: "./target.component.html"
})
export class TargetComponent implements OnInit, OnDestroy {
    @ViewChild(ZxEventsComponent) zxEvents: ZxEventsComponent;
    @ViewChild(TargetTracerouteComponent) targetTraceroute: TargetTracerouteComponent;
    @ViewChild("targetTabset") targetTabset: NgbNav;

    target: AnyTarget;
    targetId: number;
    targetName: string;
    targetApiType: TargetApiType;

    RecoveryState = RecoveryState;
    recoveryState: RecoveryState;

    resourceTags: Tag[];
    tagType: string;
    targetDashboardLink: string;

    viewOption = "accordions";
    view = "accordions";
    userPermissions: UserPermissions;

    activeTab: string;
    tabsetHasScroll: boolean;
    loadingDetails = true;
    private sourcesService = inject(SourcesService);
    targetSource$ = new Observable<Source>(null);
    isMobile$ = inject(ResizeService).isMobile$;
    isHuge$ = inject(ResizeService).isHuge$;
    showOverlayControl = new FormControl(false);
    private targetsSubscription: Subscription;
    private detailPanelViewSubscription: Subscription;

    @HostListener("window:resize", [])
    private onResize() {
        this.hasScroll();
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private ts: TargetsService,
        private modalService: ModalService,
        private sharedService: SharedService,
        private userService: UsersService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService,
        private gs: GraphsService
    ) {
        this.route.paramMap.subscribe(params => {
            this.targetId = urlBuilder.decode(params.get("targetId"));
            this.targetName = params.get("name");
            const targetType = params.get("type");

            switch (targetType) {
                case Constants.urls.targetTypes.http:
                    this.targetApiType = TargetApiType.Http;
                    this.tagType = OBJECT_TYPE.HTTP_PUBLISHING_TARGET;
                    break;
                case Constants.urls.targetTypes.pull:
                    this.targetApiType = TargetApiType.Pull;
                    this.tagType = OBJECT_TYPE.ZIXI_PULL_TARGET;
                    break;
                case Constants.urls.targetTypes.push:
                    this.targetApiType = TargetApiType.Push;
                    this.tagType = OBJECT_TYPE.ZIXI_PUSH_TARGET;
                    break;
                case Constants.urls.targetTypes.udp_rtp:
                    this.targetApiType = TargetApiType.UdpRtp;
                    this.tagType = OBJECT_TYPE.UDP_RTP_TARGET;
                    break;
                case Constants.urls.targetTypes.rtmp:
                    this.targetApiType = TargetApiType.Rtmp;
                    this.tagType = OBJECT_TYPE.RTMP_PUSH_TARGET;
                    break;
                case Constants.urls.targetTypes.rist:
                    this.targetApiType = TargetApiType.Rist;
                    this.tagType = OBJECT_TYPE.RIST_TARGET;
                    break;
                case Constants.urls.targetTypes.srt:
                    this.targetApiType = TargetApiType.Srt;
                    this.tagType = OBJECT_TYPE.SRT_TARGET;
                    break;
                case Constants.urls.targetTypes.ndi:
                    this.targetApiType = TargetApiType.Ndi;
                    this.tagType = OBJECT_TYPE.NDI_TARGET;
                    break;
                case Constants.urls.targetTypes.cdi:
                    this.targetApiType = TargetApiType.CDI;
                    this.tagType = "mediaconnect_cdi_targets";
                    break;
                case Constants.urls.targetTypes.jpegxs:
                    this.targetApiType = TargetApiType.JPEGXS;
                    this.tagType = "mediaconnect_jpegxs_targets";
                    break;
                case Constants.urls.targetTypes.medialive_http:
                    this.targetApiType = TargetApiType.MediaLiveHttp;
                    this.tagType = "medialive_http_targets";
                    break;
                case Constants.urls.targetTypes.entitlement:
                    this.targetApiType = TargetApiType.Entitlement;
                    this.tagType = "mediaconnect_entitlement_targets";
                    break;
                case Constants.urls.targetTypes.mediaconnect:
                    this.targetApiType = TargetApiType.Mediaconnect;
                    this.tagType = "mediaconnect";
                    break;
                default:
                    return this.cancel();
            }

            // Resource Tags
            this.sharedService
                .getResourceTagsByType(this.tagType ? this.tagType : "resource")
                .pipe(take(1))
                .subscribe((tags: Tag[]) => {
                    this.resourceTags = tags;
                });

            if (this.targetId) {
                this.target = this.ts.getCachedTarget(this.targetId, this.targetApiType);
                const targetSourceID = this.getTargetSourceId();
                this.targetSource$ = targetSourceID ? this.sourcesService.refreshSource(targetSourceID) : null;
            }
            if (!this.target) return this.cancel();

            // Set Title
            this.titleService.setTitle(this.translate.instant("TARGET") + " - " + this.target.target.name);

            // Loaded Details?
            if (!this.target.target.hasFullDetails) this.loadingDetails = true;
            this.ts.refreshTarget(this.targetApiType, this.target.objId, true);
        });

        this.route.queryParams.subscribe(params => {
            setTimeout(() => {
                if (params.f && params.f === "insights") {
                    if (this.targetTabset) this.targetTabset.select("insights-tab");
                    else this.scrollTo("ngb-panel-insights");
                }
            }, 0);
        });
    }

    scrollTo(section: string) {
        document.getElementById(section).scrollIntoView({ behavior: "smooth", block: "center" });
    }

    refresh() {
        if (this.zxEvents) this.zxEvents.refresh();
        if (this.targetTraceroute) this.targetTraceroute.refresh();
    }

    isTargetDown(target: AnyTarget) {
        if (target.target.status && !target.target.status.stats.net_bitrate) return true;
        return false;
    }

    async ngOnInit() {
        // Get view from local storage
        if (localStorage.getItem("detail-panel-view")) {
            this.sharedService.setDetailPanelView(localStorage.getItem("detail-panel-view"));
            this.viewOption = localStorage.getItem("detail-panel-view");
        } else {
            this.sharedService.setDetailPanelView("accordions");
            this.viewOption = "accordions";
        }

        this.targetsSubscription = this.ts.targets.subscribe(targets => {
            this.target = targets.find(
                (t: AnyTarget) => t.target.name === this.targetName && t.apiType === this.targetApiType
            );
            if (this.target && this.target.target.hasFullDetails) {
                // Check if selected tab exists
                // Child Targets
                if (this.activeTab === "child-targets-tab" && !this.target.dynamic)
                    this.targetTabset.select("details-tab");
                // History
                if (
                    this.activeTab === "history-tab" &&
                    !(this.target.target.mediaconnect_flow_id == null && !this.target.dynamic)
                )
                    this.targetTabset.select("details-tab");
                // Help
                if (this.activeTab === "help-tab" && !(this.target.pull || this.target.srt))
                    this.targetTabset.select("details-tab");
                // Traceroute
                if (
                    this.activeTab === "traceroute-tab" &&
                    !(
                        this.target.target.is_enabled &&
                        this.target.target.status &&
                        this.target.target.status?.trace &&
                        !this.target.dynamic
                    )
                )
                    this.targetTabset.select("details-tab");

                const targetSourceID = this.getTargetSourceId();
                this.targetSource$ = targetSourceID ? this.sourcesService.refreshSource(targetSourceID) : null;

                this.targetDashboardLink = this.gs.custom(this.gs.target(this.target));
                //
                this.recoveryState = this.getDisasterRecoveryState();
                this.loadingDetails = false;
            }
        });

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });

        // View Subscription
        this.detailPanelViewSubscription = this.sharedService.getDetailPanelView.subscribe(val => {
            this.view = val;
        });

        // Determine if scrollbar is needed for tabs
        window.setTimeout(() => {
            this.hasScroll();
        }, 0);
    }

    ngOnDestroy() {
        if (this.targetsSubscription) this.targetsSubscription.unsubscribe();
        this.detailPanelViewSubscription.unsubscribe();
    }

    hasScroll() {
        if (this.targetTabset) {
            if (
                document.getElementsByClassName("nav nav-tabs justify-content-start")[0].scrollWidth >
                document.getElementsByClassName("nav nav-tabs justify-content-start")[0].clientWidth
            ) {
                this.tabsetHasScroll = true;
            } else {
                this.tabsetHasScroll = false;
            }
        }
    }

    scrollLeft() {
        document.getElementsByClassName("nav nav-tabs justify-content-start")[0].scrollLeft = 0;
    }

    scrollRight() {
        document.getElementsByClassName("nav nav-tabs justify-content-start")[0].scrollLeft = 1000;
    }

    cancel() {
        this.gotoTargets();
    }

    gotoTargets() {
        this.router.navigate([Constants.urls.targets]);
    }

    async deleteTarget() {
        await this.modalService.confirm(
            "DELETE",
            "TARGET",
            async () => {
                const id = this.target.id;
                const result = await this.ts.deleteTarget(this.target.target);
                if (result) {
                    this.mixpanelService.sendEvent("delete target", { id });
                    this.gotoTargets();
                } else {
                    return false;
                }
            },
            this.target.target.name,
            { warning: this.target.dynamic ? "DYNAMIC_PULL_DELETE_WARNING" : null }
        );
    }

    editTarget(name: string) {
        return this.router.navigate(urlBuilder.getTargetActionUrl(this.targetId, this.target.apiType, name, "edit"));
    }

    cloneTarget(name: string) {
        return this.router.navigate(urlBuilder.getTargetActionUrl(this.targetId, this.target.apiType, name, "clone"));
    }

    async toggle() {
        let action = "";
        const model = {
            is_enabled: this.target.target.is_enabled
        };
        if (this.target.target.is_enabled === 1) {
            action = "DISABLE";
            model.is_enabled = 0;
        } else {
            action = "ENABLE";
            model.is_enabled = 1;
        }

        await this.modalService.confirm(
            action,
            "TARGET",
            async () => {
                const result = await this.ts.updateTarget(this.target.target, model);
                if (result) {
                    this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " target", {
                        id: this.target.id
                    });
                    return true;
                } else {
                    return false;
                }
            },
            this.target.target.name
        );
    }

    canEdit(target: AnyTarget) {
        if (!target) return false;
        return this.sharedService.canEditZixiObject(target.target, this.resourceTags, this.userPermissions);
    }

    viewChange() {
        this.sharedService.setDetailPanelView(this.viewOption);
        localStorage.setItem("detail-panel-view", this.viewOption);
        window.setTimeout(() => {
            this.hasScroll();
        }, 0);
    }

    async switchChannel() {
        const result = await this.modalService.switchChannel(
            [this.target],
            this.target.adaptive ? "adaptive" : "delivery"
        );
        if (result) {
            this.mixpanelService.sendEvent("switch channel", {
                id: this.target.id
            });
            this.ts.refreshTarget(this.targetApiType, this.target.objId, true);
        }
    }

    pullTargetWithChildren() {
        return [this.target.target, ...(this.target.target.childZixiPulls || [])];
    }

    public refreshTarget = () => {
        this.ts.refreshTarget(this.target.apiType, this.target.target.id, true);
    };

    refreshTargetPromise() {
        return firstValueFrom(this.ts.refreshTarget(this.target.apiType, this.target.target.id, true));
    }

    targetBackendType() {
        return this.target.apiType === "push"
            ? "zixi_push"
            : this.target.apiType === "pull"
            ? "zixi_pull"
            : this.target.apiType === "rtmp"
            ? "rtmp_push"
            : this.target.apiType === "rist"
            ? "rist"
            : this.target.apiType === "udp_rtp"
            ? "udp_rtp"
            : this.target.apiType === "http"
            ? "publishing_target"
            : this.target.apiType === "medialive_http"
            ? "medialive_http_targets"
            : this.target.apiType === "srt"
            ? "srt_targets"
            : this.target.apiType === "ndi"
            ? "ndi_targets"
            : this.target.apiType === "cdi"
            ? "mediaconnect_cdi_targets"
            : this.target.apiType === "jpegxs"
            ? "mediaconnect_jpegxs_targets"
            : this.target.apiType === "entitlement"
            ? "mediaconnect_entitlement_targets"
            : null;
    }

    objectTrackingApiType() {
        return `targets/${this.target.apiType}`;
    }

    getTargetSourceId(): number | null {
        const sourceId =
            (this.target?.target as unknown as { activeBroadcasterObjects: ObjectsActiveBroadcaster<any> })
                ?.activeBroadcasterObjects?.source_id || this.target?.target?.status?.active_broadcaster?.source_id;
        if (sourceId) return Number(sourceId);
        const bitrates = this.target?.target?.adaptiveChannel?.bitrates; // In case the target is publishing target;
        if (bitrates?.length) return bitrates[0]?.source_id;
    }

    getDisasterRecoveryState() {
        const target = this.target.target;
        if (!(target as any).primary_channel_id) return RecoveryState.none;

        if ((target as any).primary_channel_id === target.delivery_channel_id) {
            if (this.target.channel_type === "failover") {
                if (target.deliveryChannel?.failoverChannel?.alt_channel_id) return RecoveryState.primary;
            }
            if (target.deliveryChannel?.alt_channel_id) return RecoveryState.primary;
            return RecoveryState.none;
        } else if ((target as any).primary_channel_id === target.adaptive_channel_id) {
            if (target.adaptiveChannel?.alt_channel_id) return RecoveryState.primary;
            return RecoveryState.none;
        }
        return RecoveryState.alternative;
    }

    async goToDR() {
        // TODO: also add preferred source
        if (this.recoveryState === RecoveryState.none) return;
        const target = this.target.target as TargetPidMappingTypes;

        let requestParams = {};

        if (this.recoveryState === RecoveryState.primary) {
            if (this.target.adaptive) {
                requestParams = { adaptive_channel_id: target.adaptiveChannel.alt_channel_id };
            } else if (this.target.delivery) {
                if (this.target.channel_type === "failover") {
                    requestParams = {
                        failover_channel_id: target.deliveryChannel.failoverChannel.alt_channel_id
                    };
                } else {
                    requestParams = { delivery_channel_id: target.deliveryChannel.alt_channel_id };
                }
            }
        } else if (this.recoveryState === RecoveryState.alternative) {
            if (this.target.adaptive) {
                requestParams = { adaptive_channel_id: target.primary_channel_id };
            } else if (this.target.delivery) {
                if (this.target.channel_type === "failover") {
                    requestParams = { failover_channel_id: target.primary_channel_id };
                }
                requestParams = { delivery_channel_id: target.primary_channel_id };
            }
        }

        await this.ts.updateTarget(target, requestParams);
        this.refreshTarget();
        this.recoveryState = this.getDisasterRecoveryState();
    }
}
