import { ComponentRef } from "@angular/core";
import { KeyMap } from "../../../../../models/shared";
import { AnyTarget } from "../../../../../pages/channels/channel";
import { ZxTargetChannelColumnComponent } from "./zx-target-channel-column.component";

export function assignComponentsTargetChannelsColumnAdapter(
    statusComponentRef: ComponentRef<ZxTargetChannelColumnComponent>,
    row: KeyMap<AnyTarget>,
    searchTerm: string[]
) {
    const statusComponentInstance = statusComponentRef.instance;
    statusComponentInstance.target = row;
    statusComponentInstance.searchTerm = searchTerm.toString();
}
